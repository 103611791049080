<script setup lang="ts">
const colorMode = useColorMode({
  attribute: 'data-theme',
})
</script>

<template>
  <div class="dropdown">
    <div tabindex="0" role="button" class="btn btn-ghost btn-circle">
      <iconify-icon icon="lucide:moon" class="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
      <iconify-icon icon="lucide:sun" class="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
      <span class="sr-only">Toggle theme</span>
    </div>
    <ul tabindex="0" class="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
      <li><a @click="colorMode = 'light'"><iconify-icon v-if="colorMode === 'light'" icon="lucide:check" class="mr-1" /> Light</a></li>
      <li><a @click="colorMode = 'dark'"><iconify-icon v-if="colorMode === 'dark'" icon="lucide:check" class="mr-1" /> Dark</a></li>
      <li><a @click="colorMode = 'auto'"> System</a></li>
    </ul>
  </div>
</template>
